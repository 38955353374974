<template>
  <div>
    <table-component
      :model="model"
    />
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import BlogTag from '@/dc-it/models/BlogTag'

export default {
  name: 'BlogTagsView',
  components: { TableComponent },
  data() {
    return {
      model: BlogTag,
    }
  },
}
</script>

<style scoped>

</style>
