import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class BlogTag extends Model {
    endpoint = 'blog_tags';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name_es', 'Nombre en español'),
      new Field('name_en', 'Nombre en inglés'),
      new Field('color', 'Color', 'color').hide(),
    ]

    clone = () => BlogTag;
}
